td {
    vertical-align: bottom;
}

td ul {
    margin-bottom: 0;
    margin-right: 30px;
}

.accordion-content {
    table {
        margin-bottom: 15px;
    }
}
@include media-breakpoint-down(xs) {
    #accordion-item-price {
        table tr {
            td:last-child {
                width: 20%;
            }
        }
    }
}

.w4-price-booking-container {
    font-weight: $font-weight-bold;
    text-align: center;
    overflow: hidden;
    font-family: $font-family-playfair;
    color: $primary;
    margin-bottom: 40px;

    @if($env == 'mov'){
        font-family: $font-family-roboto;
    }

    span {
        display: block;
    }

    .price-line-big {
        font-size: 3.125rem;
        margin-top: 10px;
    }
    .price-line-small {
        font-size: 1.375rem;
    }

    .w4-button-anfrage {
        margin-top: 40px;
    }
}

.w4-button-anfrage {
    text-align: center;
}